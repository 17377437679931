import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql, navigate } from 'gatsby'

import styled from 'styled-components'

import MenuSection from './menu'
import SocialMediaIcons from './social-media-icons'

import MainLogo from './icons_and_logos/bananas-logo'
import MailIcon from './icons_and_logos/mailicon'

import './layout.css'

const LayoutGridWrapper = styled.div`
  display: grid;
  grid-template-rows: 75px 1fr 10fr 1fr;
  grid-template-columns: 125px 75px 1fr 80px;

  height: 100%;
`

const MainLogoStyled = styled(MainLogo)`  
  grid-row: 1;
  grid-column: 1;
  
  width: 80px;

  place-self: center;

  cursor: pointer;
`

const BarLinksSection = styled.div`
  grid-row: 1;
  grid-column: 3;
  justify-self: end;
  align-self: center;

  display: flex;
  align-items: center;

  height: 50%;

  & > * {
    margin: 0 5px;
  }
`

const SidewaysText = styled.div`
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    text-align: center;
    text-orientation: sideways;
    width: 100%;
    height: 100%;
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
          link={[
            { rel: 'shortcut icon', type: 'image/png', href: 'https://s3.amazonaws.com/valentina-site/faviconbanana.png' }
          ]}
        >
          <html lang="en" />
        </Helmet>
        <LayoutGridWrapper className="layout-grid-wrapper">
          <MainLogoStyled onClick={() => navigate('/')} />
          <MenuSection />
          <BarLinksSection>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <a target="_blank" href="https://colorbot.design">ColorBot</a>
            </div>
            <a href="mailto:valentinaramirezdesign@gmail.com">
              <MailIcon width={50} />
            </a>
          </BarLinksSection>
          <div
            style={{
              textTransform: 'uppercase',
              writingMode: 'sideways-lr',
              gridRow: 3,
              height: '100%',
              textAlign: 'center',
              margin: '0 auto',
              color: '#c3c6cc',
              fontSize: '18px',
              fontWeight: 200,
            }}
          >
            <SidewaysText>
              Valentina | Ramirez | Design
            </SidewaysText>
          </div>
          {children}
          <SocialMediaIcons />
        </LayoutGridWrapper>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

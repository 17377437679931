import React from 'react'

const LinkedInIcon = props => (
  <svg
    version={1.1}
    id="Layer_1"
    x={0}
    y={0}
    viewBox="0 0 32.5 31.5"
    xmlSpace="preserve"
    {...props}
  >
    <style>{`.st1{fill:#318faf}`}</style>
    <g opacity={0.88}>
      <path
        className="st1"
        d="M27.3 25.1h-4.5v-.5-6.5c0-.4 0-.8-.1-1.3-.2-1.4-1.1-2.1-2.4-2-1.2.1-2.2 1.1-2.3 2.4v7.9h-4.5V11.5H18v1.8l.3-.3c1.2-1.6 2.8-2.1 4.7-1.8 1.9.2 3.2 1.2 3.8 3 .3.7.4 1.5.6 2.3-.1 2.9-.1 5.7-.1 8.6zM6.1 6.8C6.7 5.3 7.7 4.7 9.2 5c1.3.2 2.1 1.2 2 2.5-.1 1.2-1 2.1-2.3 2.1-1.4.1-2.1-.4-2.8-1.9v-.9zM6.5 11.5H11v13.6H6.5V11.5z"
      />
    </g>
  </svg>
)

export default LinkedInIcon


import React from 'react'

import styled from 'styled-components'

import { Link } from 'gatsby'

const MenuLink = styled.div`
      font-size: 20px;
      font-weight: bold;

      & a:hover {
          color: #86dccc;
      }
`

const MenuLinks = () => (
    <>
        <MenuLink><Link to="/portfolio">Portfolio</Link></MenuLink>
        <MenuLink><Link to="/artisans_and_world">Artisans &amp; World</Link></MenuLink>
        <MenuLink><Link to="/research">Research</Link></MenuLink>
        <MenuLink><Link to="/ceramics">Ceramics</Link></MenuLink>
        <MenuLink><Link to="/about">About</Link></MenuLink>
        <MenuLink><Link to="/press">Press</Link></MenuLink>
        <MenuLink><Link to="/contact">Contact</Link></MenuLink>
    </>
)

export default MenuLinks
import React from 'react'

const MailIcon = props => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 57 40.8"
    {...props}
  >
    <defs>
      <path id="a" d="M6.5 5.2h39.2v27.9H6.5z" />
    </defs>
    <clipPath id="b">
      <use xlinkHref="#a" overflow="visible" />
    </clipPath>
    <path
      d="M8.6 7.1v24.1H43.5V7.1H8.6m1 2v-1h33.1V12c0 .2-.1.4-.2.5-.1.1-.2.2-.2.3-.2.2-.4.3-.6.5-4.8 2.3-9.7 4.5-14.6 6.8-.3.1-.6.2-.9.2-.3 0-.6 0-.9-.2-4.9-2.2-9.7-4.5-14.6-6.8-.2-.1-.4-.2-.5-.3-.1-.1-.3-.3-.4-.5-.1-.1-.1-.3-.2-.5v-1.4-1.5h1.1-1.1zm31.5 5.5l1.5-.7v16.2H9.7V14l1.5.6c.3.1.6.2.9.4 4.6 2.2 9.3 4.3 13.9 6.5h.2c4.6-2.2 9.2-4.3 13.8-6.4.4-.2.7-.4 1.1-.5"
      clipPath="url(#b)"
      fill="#eabea1"
    />
    <path
      d="M45.7 5.2H6.4v28H45.7v-28zm-2.1.9h1.1-1.1zm-35 25.1V7.1h34.9v24.1H25.4c-5.6-.1-11.2 0-16.8 0m34-23.1h-33v1h1.1-1.1V12c0 .2.1.4.2.5.1.2.2.3.4.5.1.1.3.2.5.3 4.8 2.3 9.7 4.5 14.6 6.8.3.1.6.2.9.2.3 0 .6 0 .9-.2 4.9-2.2 9.7-4.5 14.6-6.8.3-.1.5-.3.6-.5.1-.1.2-.2.2-.3.1-.1.1-.3.2-.5v-1.4-2.5zm-30.9 2h28.8v1.5c-4.8 2.3-9.6 4.5-14.4 6.7-4.8-2.2-9.6-4.5-14.4-6.7v-1-.5M26 18.3zm16.6-4.4l-1.5.7c-.4.2-.7.3-1 .4-4.6 2.1-9.2 4.3-13.8 6.4h-.2c-4.6-2.2-9.3-4.3-13.9-6.5-.3-.2-.6-.3-.9-.4L9.7 14v16.2h33V13.9zm-2.1 1.6h1.1-1.1zm-14.4 7.9c.4 0 .8-.1 1.1-.3C31.6 21 36 19 40.5 16.9v11.2H11.8V17c4.4 2 8.8 4.1 13.2 6.2.3.1.7.2 1.1.2"
      clipPath="url(#b)"
      fill="#fff"
    />
  </svg>
)

export default MailIcon


import React, { Component } from 'react'

import styled from 'styled-components'

import MenuLinks from './menulinks'

// import MenuIcon from './icons_and_logos/menu-icon'
import Burger from './icons_and_logos/burger-icon'

const Menu = styled.div`
    grid-row: 2;
    grid-column: 3;
    place-self: center;

    display: flex;
    justify-content: space-between;

    width: 100%;
    max-width: 980px;

    position: relative;

    padding: 0 15px;
`

const MenuActionSection = styled.div`
  justify-self: flex-start;
  
  display: flex;
  align-items: center;

  cursor: pointer;

  height: 50%;

  margin-top: -34.3px;
  margin-left: -36px;

  & > * {
    margin: 0 5px;
  }
`

const MenuText = styled.div`
    text-transform: capitalize;
    font-weight: bold;
    font-size: 20px;
`

const MenuLinksStyled = styled(MenuLinks)`
  justify-self: center;

  margin-left: 0;

  display: flex;

  margin: 0;

  & > div {
      font-size: 20px;
      font-weight: bold;
  }
`

class MenuSection extends Component {
    state = {
        open: false,
    }

    render() {
        return (
            <>
                <Burger
                    style={{ gridRow: 2, gridColumn: 2, alignSelf: 'center', cursor: 'pointer' }}
                    onClick={() => this.setState({ open: !this.state.open })}
                />
                {/* <div
                    style={{ gridRow: 2, gridColumn: 2, alignSelf: 'center', cursor: 'pointer', fontSize: '42px' }}
                    onClick={() => this.setState({ open: !this.state.open })}
                >
                    🍔
                </div> */}
                <Menu>
                    {this.state.open ? <MenuLinksStyled /> : null}
                </Menu>
            </>
        );
    }
}

export default MenuSection
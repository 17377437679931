import React from 'react'

import styled from 'styled-components'

const Wrapper = styled.div`
    width: 40px;    
        
    & > div {
        background: #318eae;
        height: 6px;
        margin-bottom: 6px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`

const Burger = (props) => (
    <Wrapper {...props}>
        <div></div>
        <div></div>
        <div></div>
    </Wrapper>
)

export default Burger
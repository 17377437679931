import React from 'react'

const InstagramIcon = props => (
  <svg
    version={1.1}
    id="Layer_1"
    x={0}
    y={0}
    viewBox="0 0 36.8 34.1"
    xmlSpace="preserve"
    {...props}
  >
    <style>{`.st0{opacity:.55;fill:#7ab2b6}`}</style>
    <path
      className="st0"
      d="M18.2 32.1c-2.5 0-4.9.2-7.3 0-4.6-.5-7.8-4.3-7.8-8.8V10.9c0-5 3.9-9 8.9-9h12.5c5 0 8.9 4 9 9v12.4c0 5.1-4 9-9 9h-6.2c-.1-.1-.1-.2-.1-.2zm0-2.6h6.2c3.6 0 6.3-2.7 6.3-6.4V10.8c0-3.6-2.7-6.3-6.3-6.3H12c-3.6 0-6.2 2.7-6.3 6.3v12.3c0 3.6 2.7 6.3 6.3 6.3 2.2.1 4.2.1 6.2.1z"
    />
    <path
      className="st0"
      d="M18.3 8.7c4.6 0 8.3 3.7 8.3 8.3 0 4.6-3.7 8.2-8.3 8.2-4.6 0-8.3-3.7-8.3-8.3 0-4.5 3.7-8.2 8.3-8.2zm5.5 8.3c0-3-2.6-5.6-5.6-5.5-3 0-5.6 2.6-5.5 5.6 0 3 2.6 5.6 5.6 5.5 3-.1 5.6-2.6 5.5-5.6zM26.4 7.6c.8 0 1.3.6 1.3 1.3-.1.7-.5 1.1-1.2 1.1-.8 0-1.2-.5-1.2-1.2-.1-.8.4-1.1 1.1-1.2z"
    />
  </svg>
)

export default InstagramIcon


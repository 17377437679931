import React from 'react';

import styled from 'styled-components'

import InstagramIcon from './icons_and_logos/instagram-icon'
import LinkedInIcon from './icons_and_logos/linkedin-icon'

const Wrapper = styled.div`
    grid-row: 3;
    grid-column: 4;

    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`

const SocialMediaIcons = () => (
    <Wrapper>
        <a target="_blank" rel="noopener noreferrer" href="https://instagram.com/ramivdesign/"><InstagramIcon width={50} /></a>
        <a target="_blank" rel="noopener noreferrer" href="https://linkedin.com/in/valentina-ramirez-sanchez/"><LinkedInIcon width={50} /></a>
    </Wrapper>
)

export default SocialMediaIcons